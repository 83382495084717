<template>
  <b-modal id="edit-user-details" hide-footer size="lg" centered hide-header>
    <div :class="$i18n.locale == 'en' ? '' : 'rtl'">
      <div class="w-100 info-container">
        <div class="header">
          <h3>{{ $t("ACCOUNTS.EDIT_TEAM_MEMBER") }}</h3>
          <p>{{ $t("ACCOUNTS.EDIT_TEAM_MEMBER_SUBTITLE") }}</p>
        </div>
        <div class="info">
          <div class="main-info">
            <img :src="(user.avatar != null)?user.avatar.url:default_avatar" />
            <div class="name-email">
              <h6>{{ user.name }}</h6>
              <p>{{ user.email }}</p>
            </div>
          </div>
          <h5 class="job">Marketing Manger</h5>
        </div>
        <div class="form">
          <b-row>
            <b-col>
              <b-form-group class="mb-3">
                <label for="status">Status</label>
                <b-form-select name="status" id="status" class="form-select" v-model="user.status" @change="changeStatus($event)">
                  <option value="1">نشط</option>
                  <option value="0">غير نشط</option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group class="mb-3">
                <label for="status">Role</label>
                <b-form-select name="type" id="type" class="form-select" v-model="user.type" @change="changeType($event)">
                  <option value="admin">مدير</option>
                  <option value="driver">سائق</option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <Button @click="handleSaveChanges">{{ $t("GLOBAL.SAVE_CHANGES") }}</Button>
              <Button custom-class="mx-3 cancel-btn" @click="handleCancel">{{ $t("GLOBAL.CANCEL") }}</Button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { updateUser } from "@/api/users.js"
import { mapActions } from "vuex"
export default {
  name: 'ModalDetails',
  props: {
    user: {
        type: Object,
    },
  },
  data() {
    return {
      type: '',
      status: '',
      default_avatar: require("@/assets/images/user.png"),
      statusList: [
        {
          id: 1,
          name: "نشط"
        },
        {
          id: 0,
          name: "غير نشط"
        }
      ],
      roles: [
        {
          id: 'admin',
          name: "مدير"
        },
        {
          id: 'driver',
          name: "سائق"
        }
      ]
    }
  },
  methods: {
    ...mapActions(["ShowToast"]),
    handleCancel() {
      this.$bvModal.hide('edit-user-details')
    },
    handleSaveChanges() {
      this.ApiService(updateUser(this.user.id, {
        status: this.user.status,
        type: this.user.type
      }))
      .then(res => {
        if(res.data.status == 'success'){
          this.$bvModal.hide("edit-user-details")
          this.ShowToast({
              title: this.$t("Update Status"),
              type: "success",
              message: this.$t("Update Success")
          })
          setInterval(()=>{
            window.location.reload()
          }, 500)
        }
      })
    },
    handleSetOptions(status, role){
      this.status = parseInt(status)
      this.type = role
    },
    changeStatus(e){
      this.status = e
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
