export const getUsersRequest = (page, type) => ({
  method: "get",
  url: `users?page=${page}&type=${type}`,
})

export const getUserByIdRequest = (id) => ({
  method: "get",
  url: `users/${id}/view`
})

export const createUser = (data) => ({
  method: "post",
  url: `users/store`,
  config: {
    data
  }
})

export const changeUserStatus = (id, data) => ({
  method: "post",
  url: `users/${id}/change_status`,
  config: {
    data
  }
})

export const deleteUser = (id) => ({
  method: "delete",
  url: `users/${id}/destroy`,
})

export const updateUser = (id, data) => ({
  method: "post",
  url: `users/${id}/update`,
  config: {
    data
  }
})