<template>
  <div class="filter">
    <b-row>
      <b-col class="py-0 my-0 px-0" lg="1">
        <b-form-group>
          <SelectSearch
            v-model="searchForm.roleId"
            :placeholder="$t('GLOBAL.ROLE')"
            :name="$t('GLOBAL.ROLE')"
            :options="roles"
            :get-option-label="(option) => option.name"
            :reduce="(option) => option.id"
            rules="required"
          />
        </b-form-group>
      </b-col>
      <b-col class="py-0 my-0 px-0" lg="10">
        <input v-model="searchForm.queryText" type="text" :placeholder="$t('GLOBAL.FULL_NAME')" />
      </b-col>
      <b-col class="py-0 my-0 px-0" lg="1">
        <Button>{{ $t("GLOBAL.SEARCH") }}</Button>
      </b-col>
    </b-row>
  </div>
</template>
  <script>
export default {
  props: {
    roles: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      searchForm: {
        roleId: '',
        queryText: ''
      }
    }
  },
  methods: {}
}
</script>
  <style lang="scss" scoped>
@import "./index.scss";
</style>
  